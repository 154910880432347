import React, { useState } from "react";

import '../assets/CSS/Admin.css'
import Analytics from "./Analytics";
import { useAdminData, useAdminHandler } from "../hooks/useAdminData";
import { ChangePrice } from "./ChangePrice";
import { AdminPanelComponent } from "./AdminPanelComponent";
import { TOKENADRESSES, czxAddress, usdtAdddress, vtcAddress } from "../config/constants";
import { formatNumber, formatNumberWithDecimals } from "../utils/numberHelper";
import { AdminPanelSelect } from "./AdminPanelSelect";
import { useNetwork } from "wagmi";
import { AdminpanelBNB } from "./AdminPanelBNB";

const Adminpanel = () => {

  const [token, setToken] = useState("VTC")

  const { vtc2usdt, usdt2vtc, czx2vtc, vtc2czx, feeReceiver, balance, decimals } = useAdminData(TOKENADRESSES[token.toLowerCase()])
  const { isHandler } = useAdminHandler()

  const { chain } = useNetwork()

  if (chain && chain.id === 56) {
    return <AdminpanelBNB />
  }

  if (!isHandler) {
    return (
      <>
        <section className="mainbox_ad adminform">
          <div className="os_container_admin mb-20">
            <div className="boxmain_ad">
              <div className="boxinner_ad">
                <h2>Invalid Admin</h2>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
  return (
    <>
      <section className="mainbox_ad adminform">
        <ChangePrice />
        <div className="os_container_admin mb-20">
          <div className="boxmain_ad">
            <div className="boxinner_ad">
              <div className="os_row_ad items-center  boxpart_ad">
                <div className="leftbx_ad">
                  <strong>Blacklist Address :</strong>
                </div>
                <div className="inputbox_ad">
                  <form >
                    <input type="text" name="getValue" autoComplete="off" placeholder="Enter Blacklist Address" />
                    <button type="submit" >Submit</button>
                  </form>
                </div>
              </div>
              <div className="os_row_ad items-center boxpart_ad">
                <div className="leftbx_ad">
                  <strong>Whitelist Address :</strong>
                </div>
                <div className="inputbox_ad">
                  <form >
                    <input type="text" name="getValue" autoComplete="off" placeholder="Enter WhiteList Address" />
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div>
              {/* <div className="os_row_ad items-center boxpart_ad">
                <div className="leftbx_ad">
                  <strong>24h Trade Volume :</strong>
                </div>
                <div className="inputbox_ad">
                  <form >
                    <input type="text" name="getValue" autoComplete="off" placeholder="Enter Amount" />
                    <button type="submit" >Submit</button>
                  </form>
                </div>
              </div> */}
              <div className="os_row_ad items-center boxpart_ad">
                <div className="leftbx_ad">
                  <strong>Balances</strong>
                </div>
                <div className="inputbox_ad">
                  <form >
                    <input disabled value={formatNumberWithDecimals(balance, decimals)} classname="inputswap text-white" type="number" name="amount" autoComplete="off" />
                    <select className="inputswapbtn" value={token} name="token" id="" onChange={(e) => {
                      setToken(e.target.value)
                    }}>
                      <option value='VTC'>VTC</option>
                      <option value='USDT'>USDT</option>
                      <option value='CUSD'>CUSD</option>
                    </select>
                    <button type="button"></button>
                  </form>
                </div>
              </div>
              <AdminPanelSelect
                lbl={`Withdraw ${token} (${formatNumber(balance, decimals)} ${token})`}
                cprice={0}
                tmethod={'withdrawAny'}
                token={token}
                idx={1}
                targs={[TOKENADRESSES[token.toLowerCase()], "0"]}
                callback={(e) => {
                  setToken(e.target.value)
                }}
              />
              {/* <div className="os_row_ad items-center boxpart_ad">
                <div className="leftbx_ad">
                  <strong>Withdraw All ({formatNumber(balance, decimals)} {token})</strong>
                </div>
                <div className="inputbox_ad">
                  <form >
                    <select className='Emergencydrop' value={token} name="token" id="" onChange={(e) => {
                      setToken(e.target.value)
                    }}>
                      <option value='VTC'>VTC</option>
                      <option value='USDT'>USDT</option>
                      <option value='CUSD'>CUSD</option>
                    </select>
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div> */}
              <AdminPanelComponent
                lbl={`Swap Fee for VTC to USDT (${vtc2usdt}%)`}
                cprice={vtc2usdt}
                tmethod={'setSwapFee'}
                targs={[vtcAddress, usdtAdddress, { vtc2usdt }]}
              />

              <AdminPanelComponent
                lbl={`Swap Fee for USDT to VTC (${usdt2vtc}%)`}
                cprice={usdt2vtc}
                tmethod={'setSwapFee'}
                targs={[usdtAdddress, vtcAddress, { usdt2vtc }]}
              />

              <AdminPanelComponent
                lbl={`Swap Fee for VTC to CUSD (${vtc2czx}%)`}
                cprice={vtc2czx}
                tmethod={'setSwapFee'}
                targs={[vtcAddress, czxAddress, { vtc2czx }]}
              />

              <AdminPanelComponent
                lbl={`Swap Fee for CUSD to VTC (${czx2vtc}%)`}
                cprice={czx2vtc}
                tmethod={'setSwapFee'}
                targs={[czxAddress, vtcAddress, { czx2vtc }]}
              />

              <AdminPanelComponent
                lbl={`Change Fee Address`}
                cprice={feeReceiver}
                tmethod={'setFeeReceiver'}
                targs={[feeReceiver]}
                idx={0}
                take_as_string
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Adminpanel;
