import { useCallback, useEffect, useState } from "react"
import { useAdminCall, useAdminData } from "../hooks/useAdminData"
import { useTransaction } from "../hooks/useTransaction"
import { czxAddress, usdtAdddress, vtcAddress } from "../config/constants"
import { parseEther } from "viem"
import { Loader } from "./Loader"

export const AdminPanelComponent = ({
    lbl,
    cprice,
    tmethod,
    targs,
    idx = 2,
    take_as_string = false
}) => {

    const [method, setMethod] = useState(tmethod)
    const [args, setArgs] = useState(targs)
    const [price, setPrice] = useState(take_as_string ? "" : 0)

    const { write, isError, data, isPrepareError, error, prepareError, isLoading } = useAdminCall({
        method,
        args
    })

    const { isLoading: hashLoading, error: hashError, isError: hashIsError } = useTransaction({
        hash: data?.hash,
    })

    useEffect(() => {
        let tempARGS = [...args]
        if (!take_as_string && parseFloat(price) === 0) return
        tempARGS[idx] = take_as_string ? price : parseEther(price.toString())
        setArgs(tempARGS)
    }, [price])

    return (
        <div className="os_row_ad items-center boxpart_ad">
            <div className="leftbx_ad">
                <strong>{lbl} :</strong>
            </div>
            <div className="inputbox_ad">
                <form >
                    <input type="text" name="amount" onChange={(e) => {
                        setPrice(e.target.value)
                    }} autoComplete="off" placeholder={cprice} />
                    <button type="button" disabled={
                        (isLoading || hashLoading)
                    } onClick={(e) => {
                        e.preventDefault()
                        write?.()
                    }}>
                        {
                            (isLoading || hashLoading) ? <>
                                <Loader width="25" />
                            </> : 'Submit'
                        }
                    </button>
                </form>
            </div>
        </div>
    )
}