import { erc20ABI, useAccount, useContractReads, useNetwork } from "wagmi"
import { SwapAddress } from "../config/constants"

export const useAccountBalance = (address, overrideChain) => {
    const { address: wallet } = useAccount()
    const { chain } = useNetwork()
    const { data } = useContractReads({
        contracts: [
            {
                address,
                abi: erc20ABI,
                functionName: "balanceOf",
                args: [
                    wallet
                ],
                chainId: overrideChain ?? chain?.id
            },
            {
                address,
                abi: erc20ABI,
                functionName: "decimals",
                chainId: overrideChain ?? chain?.id
            },
            {
                address,
                abi: erc20ABI,
                functionName: "allowance",
                chainId: overrideChain ?? chain?.id,
                args: [
                    wallet,
                    SwapAddress[chain?.id ?? 80001]
                ],
            }
        ],
        enabled: true,
        watch: true,
    })

    const balance = data?.[0]
    const decimals = data?.[1]
    const allowance = data?.[2]

    return {
        balance: balance?.result ?? "0",
        decimals: decimals?.result ?? "0",
        allowance: allowance?.result ?? "0"
    }
}