import SwapABI from "../abis/SwapABI.json"
import { erc20ABI, useContractWrite, useNetwork, usePrepareContractWrite } from "wagmi";
import { SwapAddress } from "../config/constants";
import { maxUint256, parseEther } from "viem";
import { useDebounce } from "./useDebounce";

export const useSwapCall = ({
    fromAddress,
    toAddress,
    fromAmount
}) => {
    const debounceFromAddress = useDebounce(fromAddress, 500)
    const debounceToAddress = useDebounce(toAddress, 500)
    const debounceFromAmount = useDebounce(fromAmount, 500)

    const { chain } = useNetwork()

    const { config, error: prepareError, isError: isPrepareError, isLoading: isPrepareLoading } = usePrepareContractWrite({
        address: SwapAddress[chain?.id ?? 80001],
        abi: SwapABI,
        functionName: "swap",
        args: [
            debounceFromAddress,
            debounceToAddress,
            parseEther(debounceFromAmount),
            false
        ],
        enabled: true
    })

    const { data, write, isLoading, isError, error, isSuccess, isIdle } = useContractWrite(config)

    return {
        write,
        hash: data?.hash,
        prepareError,
        isPrepareError,
        isPrepareLoading,
        isLoading,
        isError,
        error
    }
}

export const useBridgeCall = ({
    fromAddress,
    toAddress,
    fromAmount
}) => {
    const debounceFromAddress = useDebounce(fromAddress, 500)
    const debounceToAddress = useDebounce(toAddress, 500)
    const debounceFromAmount = useDebounce(fromAmount, 500)

    const { chain } = useNetwork()

    const { config, error: prepareError, isError: isPrepareError, isLoading: isPrepareLoading } = usePrepareContractWrite({
        address: SwapAddress[chain?.id ?? 80001],
        abi: SwapABI,
        functionName: "swap",
        args: [
            debounceFromAddress,
            debounceToAddress,
            parseEther(debounceFromAmount),
            true
        ],
        enabled: true
    })

    const { data, write, isLoading, isError, error, isSuccess, isIdle } = useContractWrite(config)

    return {
        write,
        hash: data?.hash,
        prepareError,
        isPrepareError,
        isPrepareLoading,
        isLoading,
        isError,
        error
    }
}


export const useApproveCall = ({ token }) => {
    const { chain } = useNetwork()
    const { config, isError: isPrepareError, error: prepareError, isLoading: approveCallLoading } = usePrepareContractWrite({
        address: token,
        abi: erc20ABI,
        functionName: "approve",
        args: [
            SwapAddress[chain?.id ?? 80001],
            maxUint256
        ],
        enabled: true
    })

    const { data, write: approve, isLoading: approveLoading } = useContractWrite(config)

    return {
        approve,
        hash: data?.hash,
        prepareError,
        isPrepareError,
        approveCallLoading,
        approveLoading
    }
}