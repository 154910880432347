import { useState } from "react"
import { useAdminBNBData, useAdminHandler } from "../hooks/useAdminData"
import { AdminPanelComponent } from "./AdminPanelComponent"
import { czxBNBAddress } from "../config/constants"

export const AdminpanelBNB = () => {

    const { czx2czx, feeReceiver } = useAdminBNBData()
    const { isHandler } = useAdminHandler()

    console.debug(czx2czx, feeReceiver)

    if (!isHandler) {
        return (
            <>
                <section className="mainbox_ad adminform">
                    <div className="os_container_admin mb-20">
                        <div className="boxmain_ad">
                            <div className="boxinner_ad">
                                <h2>Invalid Admin</h2>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

    return (
        <>
            <section className="mainbox_ad adminform">
                <div className="os_container_admin mb-20">
                    <div className="boxmain_ad">
                        <div className="boxinner_ad">
                            <AdminPanelComponent
                                lbl={`Swap Fee for CUSD Bridge (${czx2czx}%)`}
                                cprice={czx2czx}
                                tmethod={'setSwapFee'}
                                targs={[czxBNBAddress, czxBNBAddress, { czx2czx }]}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}