import React, { useEffect, useState } from "react";


import bannerimage2 from '../assets/images/comp2.gif'



import Swap from "./Swap";
import { useNetwork, useSwitchNetwork } from "wagmi";
import { BRIDGE_CHAIN, RECV_CHAIN } from "../config/constants";
import VitnixXBridge from "./Bridge";
import '../assets/CSS/style.css'


function Banner() {

  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork({
    chainId: BRIDGE_CHAIN,
  })
  const { switchNetworkAsync: switchToPolygon } = useSwitchNetwork({
    chainId: RECV_CHAIN,
  })
  const [showBridge, setShowBridge] = useState(false);

  useEffect(() => {
    if (chain?.id === BRIDGE_CHAIN) {
      setShowBridge(true);
    } else {
      setShowBridge(false);
    }
  }, [chain])



  return (
    <>
      <section className="banner-area " id="Home">
        <div className="container mx-auto ">
          <div className=" lg:flex lg:flex-row lg:items-center mb-10">
            <div className="lg:w-1/2 mt-0">
              <div className="info-group os_container2" data-aos="fade-left">
                <div className="banner-img-group">
                  <img
                    className="banner-img"
                    src={bannerimage2}
                    height={480}
                    width={480}
                    alt="banner-logo"
                  />
                </div>
                <h1 className=" text-3xl fnt   sm:text-4xl md:text-5xl mb-4 md:mb-2 md:px-0 px-3 font-semibold space-title text-center md:text-justify ">
                  <span className=" space-title text-pink-500 text-3xl    sm:text-4xl md:text-5xl  ">VitnixX </span>Swapping Platform
                </h1>
                <p className=" text-base   mt-1 md:px-0 px-3 text-center md:text-justify ">
                  Welcome to the Vitnixx Swapping Platform, your gateway to seamless and secure token swapping on the Polygon chain. Discover a world of decentralized finance where you have full control of your assets and transactions.
                </p>
                {!showBridge && <div className="swapbox mt-5 sm:mt-0 flex justify-center items-center">
                  <button className="rounded border-white border-solid border flex justify-between items-center text-center mt-6 text-white p-2" onClick={() => {
                    switchNetworkAsync()
                  }}>
                    Bridge CUSD from BSC
                  </button>
                </div>}
                {showBridge && <div className="swapbox mt-5 sm:mt-0 flex justify-center items-center">
                  <button className="rounded border-white border-solid border flex justify-between items-center text-center mt-6 text-white p-2" onClick={() => {
                    switchToPolygon()
                  }}>
                    Switch to Polygon
                  </button>
                </div>}
              </div>
            </div>


            <div className="lg:w-1/2 swapbox mt-5 sm:mt-0">

              {
                showBridge ? <VitnixXBridge /> : <Swap />
              }




            </div>


          </div>
        </div>
      </section>


    </>
  );
};

export default Banner;
