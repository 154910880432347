import { useEffect, useMemo, useState } from "react"
import useFetch from "./useFetch"

export const useBlacklist = ({
    address
}) => {
    const [isBlacklisted, setIsBlacklisted] = useState(false)
    const { data, error } = useFetch(`https://plan.vitnixx.com/checkBlacklist?walletAddress=${address}`)

    useEffect(() => {
        console.debug("useBlacklist", data, error)
        if (data) {
            if (parseInt(data['status_code']) !== 1) {
                setIsBlacklisted(true)
            }
        }
    }, [data, error])

    return useMemo(() => ({
        isBlacklisted
    }), [isBlacklisted])
}