import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

import SwapHistory from './History';
import Analytics from './Analytics';
import '../assets/CSS/style.css'

function MergedCarousel() {
    const renderCustomIndicator = (onClickHandler, isSelected, index) => {
        const labels = ['Analytics', 'History'];
        const baseClasses = "carousel-indicator-button";
        const activeClass = "carousel-indicator-active";
        const inactiveClass = "carousel-indicator-inactive";
        return (
            <button 
            key={index}
            onClick={onClickHandler}
            className={`${baseClasses} ${isSelected ? activeClass : inactiveClass}`}
        >
            {labels[index]}
        </button>
        );
    };

    return (
        <div className="relative mt-10">
            <Carousel 
              onTouchStart={(e) => e.stopPropagation()}
              onTouchMove={(e) => e.stopPropagation()}
              showStatus={false} 
              showThumbs={false} 
              showArrows={false}
              className="carousel"
              infiniteLoop={true}
              renderIndicator={renderCustomIndicator}
              autoPlay={false}
              emulateTouch={false}
              swipeable={false}
           >
                <div className="p-4">
                    <Analytics />
                </div>
                <div  className="p-4">
                    <SwapHistory />
                </div>
            </Carousel>
        </div>
    );
}

export default MergedCarousel;
