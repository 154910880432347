import { useWaitForTransaction } from "wagmi"

export const useTransaction = ({
    hash
}) => {
    const { isLoading, isSuccess, status, fetchStatus, isError, error } = useWaitForTransaction({
        hash: hash
    })

    return {
        isLoading,
        isSuccess,
        status,
        fetchStatus,
        isError,
        error
    }
}