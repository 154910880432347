import React from 'react'
import insta from "../assets/images/instagram.png"
import twitter from '../assets/images/twitter (1).png'
import medium from '../assets/images/medium.png'
import youtube from '../assets/images/youtube.png'
import facebook from '../assets/images/facebook.png'
import telegram from  '../assets/images/telegram.png'
import Reddit  from '../assets/images/reddit (1).png'
import '../assets/CSS/style.css'


const Footer = () => {
  return (
    <>
    <footer className="zm_footer">
    <div className=" container mx-auto">
      <div className="flex flex-col md:flex-row items-center  ">
        <div className="md:w-1/2">

          <div className="zm_footer-copyright-text">
            <p>
              Copyright © 2023 - All Rights Reserved By <span>VitnixX</span>
            </p>
          </div>
        </div>

        <div className="md:w-1/2">
          <div className="zm_footer-card text-center  ">
            <div className="zm_footer-social">
              <a
                href=" https://www.instagram.com/vitnixxcoinofficial/"
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <img
                  src={insta}
                  width={22}
                  height={22}
                  className="instagram"
                  alt="instagram icon"
                />
              </a>
              <a
                href=" https://twitter.com/vitnixxofficial"
                target="_blank"
                rel="noreferrer"
                title="Twitter"
              >
                <img
                  src={twitter}
                  width={22}
                  height={22}
                  alt="twitter icon"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCt5pNL0FiKDMsR74NYGdmmQ"
                target="_blank"
                rel="noreferrer"
                title="Youtube"
              >
                <img
                  src={youtube}
                  width={22}
                  height={22}
                  alt="youtube icon"
                />
              </a>
              <a
                href="https://medium.com/@vitnixxcoin"
                target="_blank"
                rel="noreferrer"
                title="Medium"
              >
                <img
                  src={medium}
                  width={22}
                  height={22}
                  alt="medium icon"
                />
              </a>
              <a
                href=" https://www.facebook.com/vitnixxcoinofficial/"
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <img
                  src={facebook}
                  width={22}
                  height={22}
                  className="instagram"
                  alt="instagram icon"
                />
              </a>
              <a
                href="https://t.me/vitnixxcoin"
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <img
                  src={telegram}
                  width={22}
                  height={22}
                  className="instagram"
                  alt="instagram icon"
                />
              </a>
              <a
                href="https://www.reddit.com/user/Vitnixxcoinofficial"
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <img
                  src={Reddit}
                  width={22}
                  height={22}
                  className="instagram"
                  alt="instagram icon"
                />
              </a>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  </>
  )
}

export default Footer