import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../src/pages/Layout'
import Home from '../src/pages/Home'
import '@rainbow-me/rainbowkit/styles.css';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { bsc, polygon } from 'wagmi/chains';
import { publicProvider } from "wagmi/providers/public"
import { RainbowKitProvider, getDefaultWallets } from '@rainbow-me/rainbowkit';
import Adminpanel from './components/Adminpanel';

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    polygon,
    bsc
  ],
  [
    publicProvider(),
    publicProvider()
  ]
)
const { connectors } = getDefaultWallets({
  appName: 'VitnixX Swap',
  projectId: '74ff0c8063613ea7db447832e6049d4d',
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const App = () => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider coolMode chains={chains} >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path='admin-panel' element={<Adminpanel />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};
export default App;