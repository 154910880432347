import { useCallback, useState } from "react"
import { useAdminCall, useAdminData } from "../hooks/useAdminData"
import { useTransaction } from "../hooks/useTransaction"
import { czxAddress, usdtAdddress, vtcAddress } from "../config/constants"
import { parseEther } from "viem"
import { ChangePriceComponent } from "./ChangePriceComponent"

export const ChangePrice = () => {
    const { czx2vtcPrice, vtc2czxPrice, vtc2usdtPrice, usdt2vtcPrice } = useAdminData()

    const [method, setMethod] = useState('setSwapRate')
    const [args, setArgs] = useState([])
    const [price, setPrice] = useState(0)

    const { write, isError, data, isPrepareError, error, prepareError, isLoading } = useAdminCall({
        method,
        args
    })

    const { isLoading: hashLoading, error: hashError, isError: hashIsError } = useTransaction({
        hash: data?.hash,
    })

    const handleChange = useCallback(async ({
        e,
        method,
        args
    }) => {
        e.preventDefault()
        setMethod(method)
        setArgs(args)
    }, [setMethod, setArgs])
    return (
        <div className="os_container_admin mb-20">
            <div className="boxmain_ad">
                <div className="boxinner_ad">
                    <ChangePriceComponent
                        slabel={"VTC"}
                        qlabel={"USDT"}
                        cprice={vtc2usdtPrice}
                        lbl={"Sell"}
                        tmethod={'setSwapRate'}
                        targs={[vtcAddress, usdtAdddress, "0"]}
                    />
                    <ChangePriceComponent
                        slabel={"VTC"}
                        qlabel={"USDT"}
                        cprice={1 / usdt2vtcPrice}
                        lbl={"Buy"}
                        tmethod={'setSwapRate'}
                        isReciprocal
                        targs={[usdtAdddress, vtcAddress, "0"]}
                    />
                    {/* <div className="os_row_ad items-center boxpart_ad">
                        <div className="leftbx_ad">
                            <strong>VTC Buy Price ( 1 VTC = {1 / usdt2vtcPrice} USDT ) :</strong>
                        </div>
                        <div className="inputbox_ad">
                            <form>
                                <input type="text" name="amount" autoComplete="off" placeholder={1 / usdt2vtcPrice} />
                                <button type="button">Submit</button>
                            </form>
                        </div>
                    </div> */}

                    <ChangePriceComponent
                        slabel={"CUSD"}
                        qlabel={"VTC"}
                        cprice={czx2vtcPrice}
                        lbl={"Buy"}
                        tmethod={'setSwapRate'}
                        targs={[czxAddress, vtcAddress, "0"]}
                    />

                    {/* <div className="os_row_ad items-center boxpart_ad">
                        <div className="leftbx_ad">
                            <strong>CUSD Buy Price ( 1 CUSD = {czx2vtcPrice} VTC ) :</strong>
                        </div>
                        <div className="inputbox_ad">
                            <form>
                                <input type="text" name="amount" autoComplete="off" placeholder={czx2vtcPrice} />
                                <button type="button">Submit</button>
                            </form>
                        </div>
                    </div> */}

                    <ChangePriceComponent
                        slabel={"CUSD"}
                        qlabel={"VTC"}
                        cprice={1 / vtc2czxPrice}
                        lbl={"Sell"}
                        tmethod={'setSwapRate'}
                        isReciprocal
                        targs={[vtcAddress, czxAddress, "0"]}
                    />

                    {/* <div className="os_row_ad items-center boxpart_ad">
                        <div className="leftbx_ad">
                            <strong>CUSD Sell Price ( 1 CUSD = {1 / vtc2czxPrice} VTC ) :</strong>
                        </div>
                        <div className="inputbox_ad">
                            <form>
                                <input type="text" name="amount" autoComplete="off" placeholder={1 / vtc2czxPrice} />
                                <button type="button">Submit</button>
                            </form>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}