import React from 'react'
import logo from '../assets/images/white 3d.png'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import '../assets/CSS/style.css'

const Header = () => {


  return (
    <>
      <header>
        <div className="container mx-auto px-4">
          <nav className=' mx-2'>
            <div className="nav-brand navimg">
              <a href="#home"><img src={logo} className=' w-[50px]   sm:w-[65px] h-auto' alt="" /></a>
            </div>
            <div className="nav-btn-area">
              <ConnectButton />
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header