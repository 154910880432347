import { swapAddress } from "../config/constants";
import SwapABI from "../abis/SwapABI.json"
import { useContractRead, useNetwork } from "wagmi";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "./useDebounce";
import { formatEther } from "viem";

export const useSwapRate = ({
    fromAddress,
    toAddress
}) => {
    const debounceFromAddress = useDebounce(fromAddress, 500)
    const debounceToAddress = useDebounce(toAddress, 500)

    const [price, setPrice] = useState(0)
    const [fee, setFee] = useState(0)
    const { chain } = useNetwork()
    const { data } = useContractRead({
        ...swapAddress(chain?.id),
        functionName: 'pairs',
        args: [
            debounceFromAddress,
            debounceToAddress
        ],
        enabled: true
    })

    const { data: adminFeeData } = useContractRead({
        ...swapAddress(chain?.id),
        functionName: 'system',
    })

    useEffect(() => {
        console.debug(data)
        if (data) {
            setPrice(formatEther(data[2]))
        }
        if (data) {
            setFee(formatEther(data[5] ?? "0"))
        }
    }, [data, adminFeeData, setPrice])

    return useMemo(() => {
        return {
            price,
            fee
        }
    }, [price])
}