import React, { useCallback, useEffect, useState } from "react";

import vitnixx from '../assets/images/20001.png';

import cusd from '../assets/images/czx.png';
import usdt from '../assets/images/usdt.png';
import swapImage from '../assets/images/swap.png';
import Select from 'react-select';
import { czxAddress, usdtAdddress, vtcAddress } from "../config/constants";
import { useSwapRate } from "../hooks/useSwapRate";
import { useAccountBalance } from "../hooks/useAccountBalance";
import { BaseError, ContractFunctionRevertedError, TransactionExecutionError, parseUnits } from "viem";
import { formatNumber, formatNumberWithDecimals } from "../utils/numberHelper";
import { useApproveCall, useSwapCall } from "../hooks/useSwapCall";
import { useTransaction } from "../hooks/useTransaction";
import { Loader } from "./Loader";
import '../assets/CSS/style.css'
import { useBlacklist } from "../hooks/useBlacklist";
import { useAccount } from "wagmi";
import toast from "react-hot-toast";


function Swap() {
    const { address } = useAccount()
    const [hueRotation, setHueRotation] = useState(0); // Initial hue rotation value

    const getRandomHueRotation = () => {
        return Math.floor(Math.random() * 360); // Generates a random degree between 0 and 360
    };

    const changeColor = () => {
        const newHueRotation = getRandomHueRotation();
        setHueRotation(newHueRotation);
    };

    const [isSwapped, setIsSwapped] = useState(false);
    const [fromCoin, setFromCoin] = useState('CUSD'); // Initial value for "from"
    const [toCoin, setToCoin] = useState('VTC'); // Initial value for "to"

    const [loader, setLoader] = useState(false); // Initial value for "from"

    const [fromAmount, setFromAmount] = useState(null)
    const [toAmount, setToAmount] = useState(null)
    const [fromAddress, setFromAddress] = useState(usdtAdddress)
    const [toAddress, setToAddress] = useState(vtcAddress)
    const [fromOption, setFromOption] = useState({ value: 'USDT', label: 'USDT', image: usdt, address: usdtAdddress })
    const [toOption, setToOption] = useState({ value: 'VTC', label: 'VTC', image: vitnixx, address: vtcAddress })
    const [adminAmount, setAdminAmount] = useState(0)

    const [isError, setIsError] = useState(false);
    const [error, setError] = useState(null);

    const { decimals, balance, allowance } = useAccountBalance(fromAddress)
    const { decimals: toDecimals, balance: toBalance } = useAccountBalance(toAddress)

    const { isBlacklisted } = useBlacklist({ address })

    const { price, fee } = useSwapRate({
        fromAddress,
        toAddress,
    })

    console.debug("price", price, fromAddress, toAddress)

    const { write, hash, prepareError, isPrepareError, isError: swapCallError, error: swapError, isLoading: swapCallLoading } = useSwapCall({
        fromAddress: fromAddress,
        toAddress: toAddress,
        fromAmount: fromAmount?.toString() ?? "0",
    })

    const { isError: isTransactionError, isLoading, isSuccess, error: transactionError } = useTransaction({
        hash: hash
    })


    const { prepareError: approvePrepareError, isPrepareError: isApproveError, approve, approveCallLoading, approveLoading, hash: approveHash } = useApproveCall({ token: fromAddress })

    const { isLoading: approveTxnLoading } = useTransaction({
        hash: approveHash
    })

    useEffect(() => {
        if (!fromAmount) return
        showLoaderFor5Seconds()
        if (price != 0) {
            let _adminAmount = fromAmount * fee / 100;
            let actualFromAmount = fromAmount - _adminAmount;
            setAdminAmount(_adminAmount)
            setToAmount(actualFromAmount * price)
        } else {
            setToAmount(0)
            setAdminAmount(0)
        }
    }, [fromAmount, price])

    const showLoaderFor5Seconds = useCallback(() => {
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
        }, 800);
    }, []);


    const cryptoOptions = [
        { value: 'VTC', label: 'VTC', image: vitnixx, address: vtcAddress },
        { value: 'USDT', label: 'USDT', image: usdt, address: usdtAdddress },
        // Add other coin options with their images
    ];

    const formatOptionLabel = (option) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={option.image} alt={option.label} style={{ width: '20px', marginRight: '10px' }} />
            {option.label}
        </div>
    );

    const handleSelectChange = (selectedOption, actionMeta) => {

        const { name } = actionMeta;

        if (name === "fromCoin" && selectedOption.value === toOption.value) {
            handleSwap();
            return
        }
        if (name === "toCoin" && selectedOption.value === fromOption.value) {
            handleSwap();
            return
        }
        showLoaderFor5Seconds()
        if (name === "fromCoin") {
            setFromAddress(selectedOption.address)
            setFromCoin(selectedOption.value);
            setFromOption(selectedOption)
        } else {
            setToAddress(selectedOption.address)
            setToCoin(selectedOption.value);
            setToOption(selectedOption)
        }
    };

    const handleSwap = () => {
        showLoaderFor5Seconds()
        const temp = fromCoin;
        const tempOption = fromOption;
        setFromOption(toOption)
        setToOption(tempOption)

        const tempFromAddress = fromAddress;
        setFromAddress(toAddress)
        setToAddress(tempFromAddress)

        setFromAmount(toAmount);
        setToAmount(0)

        setFromCoin(toCoin);
        setToCoin(temp);
        setIsSwapped(!isSwapped);
    };

    const approveCall = useCallback(async () => {
        approve?.()
    }, [approve])

    useEffect(() => {
        const getRandomHueRotation = () => {
            return Math.floor(Math.random() * 360);
        };

        const changeColor = () => {
            const newHueRotation = getRandomHueRotation();
            setHueRotation(newHueRotation);
        };

        changeColor();
        const intervalId = setInterval(changeColor, 2000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (approveCallLoading) {
            setLoader(approveCallLoading)
        } else if (approveTxnLoading) {
            setLoader(approveTxnLoading)
        } else if (swapCallLoading) {
            setLoader(swapCallLoading)
        } else if (approveLoading) {
            setLoader(approveLoading)
        } else {
            setLoader(false)
        }
    }, [swapCallLoading, approveCallLoading, approveTxnLoading, approveLoading])


    useEffect(() => {
        if (fromAmount > formatNumberWithDecimals(balance, decimals)) {
            setIsError(true)
            setError('Insufficient Balance')
            setLoader(false)
        } else if (!fromAmount) {
            setIsError(true)
            setError('Enter an amount')
        } else if (parseFloat(allowance) === 0) {
            setIsError(false)
            setError(null)
        }
        else if (isTransactionError) {
            setIsError(true)
            setError(transactionError)
            setLoader(false)
        } else if (isApproveError) {
            setIsError(true)
            setError(approvePrepareError)
            setLoader(false)
        } else if (isPrepareError) {
            setIsError(true)
            let customErr = prepareError?.walk(err => err instanceof ContractFunctionRevertedError)
            customErr = customErr?.data?.args?.[0]
            let fallbackErr = prepareError?.walk(err => err instanceof BaseError)
            fallbackErr = fallbackErr?.name
            setError(customErr || fallbackErr)
        } else if (swapCallError) {
            setLoader(false)
        } else {
            setIsError(false)
            setError(null)
        }
    }, [isTransactionError, isLoading, error, transactionError, isApproveError, approvePrepareError, isPrepareError, prepareError, swapCallError, swapError, fromAmount, balance, decimals])

    useEffect(() => {
        if (isSuccess) {
            setFromAmount(0)
        }
    }, [isSuccess])

    const addToken = ({
        address,
        image,
        symbol
    }) => {
        if (window.ethereum) {
            window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address,
                        symbol,
                        decimals: 18,
                        image
                    }
                }
            })
                .then(() => {
                    toast.success(`${symbol} added to wallet`)
                })
                .catch((error) => {
                    console.log(error)
                    toast.error(`Error adding ${symbol} to wallet: ${error.message}`)
                })
        }
    }


    return (
        <div className="os_container mx-auto py-3 ">
            <h2 className="title text-center text-3xl mb-3  font-semibold"><span className=" text-pink-500">VitnixX</span> Swap</h2>
            <div className="boxmain p-5  space-y-2">

                <div className="boxinner space-y-4">
                    <div className="flex justify-between items-center">
                        <p className="w-1/2">From<br />
                            <button className="addtoken" onClick={() => {
                                addToken({
                                    address: fromOption.address,
                                    image: `https://swap.vitnixx.com/${fromOption.value.toLowerCase()}.png}`,
                                    symbol: fromOption.value,
                                })
                            }}>Add {fromOption.value} to Wallet</button>
                        </p>
                        <strong>Balance : <span>{
                            formatNumber(balance, decimals)
                        }</span></strong>
                    </div>
                    <div className="flex items-center space-x-4">
                        <div className="leftbx flex items-center space-x-2">
                            <Select
                                name="fromCoin"
                                value={fromOption}
                                options={cryptoOptions}
                                formatOptionLabel={formatOptionLabel}
                                onChange={handleSelectChange}
                                className="coin-dropdown"
                            />
                        </div>
                        <input type="number" value={fromAmount} onChange={(e) => {
                            setFromAmount(e.target.value)
                        }} placeholder={`Enter ${fromCoin} Amount`} className="border p-2 flex-1" />
                    </div>
                </div>

                <img src={swapImage} alt="Swap" className="mx-auto my-4 cursor-pointer w-12 h-12 invert" onClick={handleSwap} />

                <div className="boxinner space-y-4">
                    <div className="flex justify-between items-center">
                        <p className="w-1/2">To<br />
                            <button className="addtoken" onClick={() => {
                                addToken({
                                    address: toOption.address,
                                    image: `https://swap.vitnixx.com/${toOption.value.toLowerCase()}.png}`,
                                    symbol: toOption.value,
                                })
                            }}>Add {toOption.value} to Wallet</button>
                        </p>
                        <strong>Balance : <span>{
                            formatNumber(toBalance, toDecimals)
                        }</span></strong>
                    </div>
                    <div className="flex items-center space-x-4">
                        <div className="leftbx flex items-center space-x-2">
                            <Select
                                name="toCoin"
                                value={toOption}
                                options={cryptoOptions}
                                formatOptionLabel={formatOptionLabel}
                                onChange={handleSelectChange}
                                className="coin-dropdown"
                            />
                        </div>

                        <input type="number" value={toAmount} onChange={(e) => {
                            setToAmount(e.target.value)
                        }} disabled placeholder={`Output Amount`} className="border p-2 flex-1" />
                    </div>
                </div>




                <div className="boxinner bxinner space-y-4">
                    <div className="flex justify-between items-center py-1">
                        <p className="!text-xs">Conversion Rate: </p>
                        <strong className="">
                            <span className="mr-2 text-sm">1 {fromOption.value}</span> ~ <span className="text-sm">{parseFloat(price).toFixed(2)} {toOption.value}</span>
                        </strong>
                    </div>
                    <div className="flex justify-between items-center py-1">
                        <p className="!text-xs">Swap Fees ({fee}%): </p>
                        <strong>
                            <span className="text-sm">{parseFloat(adminAmount).toFixed(4)} {fromOption.value}</span>
                        </strong>
                    </div>
                </div>
                {parseFloat(allowance) === 0 && !isError && <button className="approvebtn bxinner flex justify-center text-center items-center mt-6  text-white p-2 rounded" disabled={approveLoading || approveTxnLoading} onClick={approveCall}>
                    {!approveLoading && !approveTxnLoading && `Approve ${fromOption.value}`}
                    {approveLoading && <Loader />}
                    {approveTxnLoading && <div className="flex justify-center items-center">Approving <Loader /></div>}
                </button>}
                {
                    isError && <button className="approvebtn bxinner flex justify-center text-center items-center mt-6 text-white p-2 rounded" disabled>
                        {error}
                    </button>
                }
                {!isBlacklisted && !isError && <>

                    {parseFloat(allowance) > 0 && fromAmount > 0 && <button className="approvebtn bxinner flex justify-center items-center text-center mt-6 text-white p-2 rounded" disabled={!write || isLoading} onClick={() => {
                        write?.()
                    }}>
                        {(!isLoading && !loader) && `Swap ${fromOption.value}`}
                        {(isLoading) && <div className="flex justify-center items-center">
                            Swapping <Loader />
                        </div>}
                        {loader && !isLoading && <Loader />}
                    </button>}

                    {(parseFloat(fromAmount) === 0 || !fromAmount) && <button className="approvebtn bxinner flex justify-center items-center text-center mt-6 text-white p-2 rounded" disabled>
                        Enter an amount
                    </button>}
                </>
                }
                {
                    isBlacklisted && <></>
                }

                {/* {
                    isError && parseFloat(allowance) !== 0 && <button className="approvebtn bxinner flex justify-between items-center mt-6 text-white p-2 rounded" disabled>
                        {error && error?.data?.args?.[0]}
                    </button>
                } */}
            </div>
        </div>
    );
}

export default Swap;






