import React from 'react'
import Banner from '../components/Banner'
import Swap from '../components/Swap'
import Analytics from '../components/Analytics'
import History from '../components/History'
import Nft_history from '../components/Nft_history'
// import '../assets/CSS/style.css'
import Adminpanel from '../components/Adminpanel'



const Home = () => {
  return (
    <>

      <Banner />
      {/* <Adminpanel /> */}

      {/* <Analytics />
        <History /> */}
      {/* <Nft_history /> */}


    </>
  )
}

export default Home