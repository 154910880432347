import { erc20ABI } from "wagmi"
import SwapABI from "../abis/SwapABI.json"

export const SwapAddress = {
    80001: "0x870342634b08fDc2046e33143c6448bb968Dd199",
    97: "0x625f107DbFaD2AF06C72AB0399eB48740110a4da",
    137: "0x6d3CE50Dd737278c3e7E8cB3c3A623e3DF6d966c",
    56: "0x7d24903B4c4c81456cf9Bf66b73CE95Dc6914950"
}

export const BRIDGE_CHAIN = 56
export const RECV_CHAIN = 137
export const czxAddress = "0x7ad95eb6064bf69870a9dd4dafdff941bf60ae88"
export const czxBNBAddress = "0x5dAd4ec3A1a372550B199E7B2114dE74924A63bB"
export const vtcAddress = "0x7d24903B4c4c81456cf9Bf66b73CE95Dc6914950"
export const usdtAdddress = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f"

export const TOKENADRESSES = {
    cusd: czxAddress,
    czxBNB: czxBNBAddress,
    vtc: vtcAddress,
    usdt: usdtAdddress
}

export const swapAddress = (chainID) => {
    return {
        address: SwapAddress[chainID ?? 137],
        abi: SwapABI
    }
}

export const czxToken = {
    address: czxAddress,
    abi: erc20ABI
}

export const vtcToken = {
    address: vtcAddress,
    abi: erc20ABI
}

export const usdtToken = {
    address: usdtAdddress,
    abi: erc20ABI
}