import SwapABI from "../abis/SwapABI.json"
import { useEffect, useMemo, useState } from "react"
import { erc20ABI, useAccount, useContractRead, useContractWrite, useNetwork, usePrepareContractWrite } from "wagmi"
import { SwapAddress, swapAddress } from "../config/constants"
import { czxAddress, czxBNBAddress, usdtAdddress, vtcAddress } from "../config/constants"
import { formatEther, formatUnits } from "viem"
import { useDebounce } from "./useDebounce"

export const useAdminHandler = () => {
    const [isHandler, setIsHandler] = useState(false)

    const { address } = useAccount()
    const { chain } = useNetwork()

    const { data: handlerData } = useContractRead({
        ...swapAddress(chain?.id),
        functionName: 'isHandler',
        args: [
            address
        ],
        enabled: true,
        watch: true
    })

    useEffect(() => {
        if (handlerData) {
            setIsHandler(handlerData)
        }
    }, [handlerData, setIsHandler, address])

    return {
        isHandler
    }
}

export const useAdminData = (token) => {
    const { chain } = useNetwork()
    const [czx2vtcPrice, setCZX2VTCPrice] = useState(0)
    const [vtc2czxPrice, setVTC2CZXPrice] = useState(0)
    const [vtc2usdtPrice, setVtc2UsdtPrice] = useState(0)
    const [usdt2vtcPrice, setUsdt2VtcPrice] = useState(0)
    const [vtc2usdt, setFee] = useState(0)
    const [vtc2czx, setVTC2CZXFee] = useState(0)
    const [czx2vtc, setCZX2VTCFee] = useState(0)
    const [usdt2vtc, setVtcFee] = useState(0)
    const [feeReceiver, setFeeReceiver] = useState("")
    const [balance, setBalance] = useState(0)
    const [decimals, setDecimals] = useState(0)

    const debounceToken = useDebounce(token, 1000)


    const { data: czx2vtcData } = useContractRead({
        ...swapAddress(chain?.id),
        functionName: 'pairs',
        args: [
            czxAddress,
            vtcAddress
        ],
        enabled: true,
        watch: true
    })

    const { data: vtc2czxData } = useContractRead({
        ...swapAddress(chain?.id),
        functionName: 'pairs',
        args: [
            vtcAddress,
            czxAddress
        ],
        enabled: true,
        watch: true
    })

    const { data: vtc2usdtData } = useContractRead({
        ...swapAddress(chain?.id),
        functionName: 'pairs',
        args: [
            vtcAddress,
            usdtAdddress
        ],
        enabled: true,
        watch: true
    })

    const { data: systemData } = useContractRead({
        ...swapAddress(chain?.id),
        functionName: 'system',
        args: [
        ],
        enabled: true,
        watch: true
    })

    const { data: usdt2vtcData } = useContractRead({
        ...swapAddress(chain?.id),
        functionName: 'pairs',
        args: [
            usdtAdddress,
            vtcAddress
        ],
        enabled: true,
        watch: true
    })

    const { data: tokenBalance } = useContractRead({
        address: debounceToken,
        abi: erc20ABI,
        functionName: 'balanceOf',
        args: [
            SwapAddress[chain?.id ?? 80001]
        ],
        enabled: true,
        watch: true
    })

    const { data: tokenDecimals } = useContractRead({
        address: debounceToken,
        abi: erc20ABI,
        functionName: 'decimals',
        args: [],
        enabled: true,
        watch: true
    })

    useEffect(() => {
        if (czx2vtcData) {
            setCZX2VTCPrice(formatEther(czx2vtcData[2]))
        }
        if (vtc2czxData) {
            setVTC2CZXPrice(formatEther(vtc2czxData[2]))
            setVTC2CZXFee(formatEther(vtc2czxData[5] ?? "0"))
        }
        if (vtc2usdtData) {
            setVtc2UsdtPrice(formatEther(vtc2usdtData[2]))
            setFee(formatEther(vtc2usdtData[5] ?? "0"))
        }
        if (usdt2vtcData) {
            setUsdt2VtcPrice(formatEther(usdt2vtcData[2]))
            setVtcFee(formatEther(usdt2vtcData[5] ?? "0"))
        }
        if (czx2vtcData) {
            setCZX2VTCFee(formatEther(czx2vtcData[5] ?? "0"))
        }

        if (systemData) {
            setFeeReceiver(systemData[2])
        }

        if (parseFloat(tokenBalance?.toString()) >= 0 && tokenDecimals) {
            setBalance(tokenBalance)
            setDecimals(tokenDecimals)
        }

        console.debug(tokenBalance, tokenDecimals)
    }, [czx2vtcData, vtc2czxData, vtc2usdtData, usdt2vtcData, setCZX2VTCPrice, setVTC2CZXPrice, setFee, setVtcFee, setCZX2VTCFee, setVTC2CZXFee, setVtc2UsdtPrice, setUsdt2VtcPrice, systemData, setFeeReceiver, tokenBalance, setBalance, tokenDecimals, setDecimals])

    return useMemo(() => {
        return {
            czx2vtcPrice,
            vtc2czxPrice,
            vtc2usdtPrice,
            usdt2vtcPrice,
            vtc2usdt,
            usdt2vtc,
            vtc2czx,
            czx2vtc,
            feeReceiver,
            balance,
            decimals
        }
    }, [czx2vtcPrice, vtc2czxPrice, vtc2usdtPrice, usdt2vtcPrice, vtc2usdt, usdt2vtc, vtc2czx, czx2vtc, feeReceiver, balance])
}

export const useAdminCall = ({
    method,
    args,
}) => {
    const { chain } = useNetwork()
    const { config, isError: isPrepareError, error: prepareError } = usePrepareContractWrite({
        address: SwapAddress[chain?.id ?? 80001],
        abi: SwapABI,
        functionName: method,
        args: args,
        enabled: true
    })

    const { write, data, isError, error, isLoading } = useContractWrite(config)

    return {
        write,
        data,
        isError,
        error,
        isLoading,
        isPrepareError,
        prepareError
    }
}

export const useAdminBNBData = () => {
    const { chain } = useNetwork()
    const [czx2czx, setCZX2VTCFee] = useState(0)
    const [feeReceiver, setFeeReceiver] = useState("")

    const { data: systemData } = useContractRead({
        ...swapAddress(chain?.id),
        functionName: 'system',
        args: [
        ],
        enabled: true,
        watch: true
    })


    const { data: czx2czxData } = useContractRead({
        ...swapAddress(chain?.id),
        functionName: 'pairs',
        args: [
            czxBNBAddress,
            czxBNBAddress
        ],
        enabled: true,
        watch: true
    })

    useEffect(() => {
        if (czx2czxData) {
            setCZX2VTCFee(formatEther(czx2czxData[5]))
        }

        if (systemData) {
            setFeeReceiver(systemData[2])
        }
    }, [setCZX2VTCFee, czx2czxData, systemData])

    return useMemo(() => {
        return {
            czx2czx,
            feeReceiver
        }
    }, [
        czx2czx,
        feeReceiver
    ])
}